import NavBar from './NavBar.js';
import NavMobile  from './NavMobile';
import NavDesktop from './NavDesktop';


export {
  NavMobile,
  NavDesktop,
}

export default NavBar;